.legal-page-body .content-cover {
  min-height: calc(100vh - 463px);
  padding: 2rem 0 3rem;
}
.legal-page-body .title {
  margin-bottom: 2rem;
}
.legal-page-body p {
  margin-bottom: 1rem;
}
.legal-page-body ul {
  margin: 0;
  padding: 0;
  list-style: circle;
  padding-left: 3rem;
  margin-bottom: 1rem;
}
.legal-page-body ul li {
  font-family: "PaytoneOne", sans-serif;
  font-size: 1.375rem;
  line-height: 1.7rem;
} 
.legal-page-body h3 {
  font-family: "PaytoneOne", sans-serif;
  font-size: 1.375rem;
  line-height: 1.7rem;
}
.goback-link {
  font-size: 1.5rem;
  -webkit-text-stroke: 1px var(--black);
}
.legal-page-body ul.footer-nav {
  padding-left: 0;
}

/* RESPONSIVE */
@media (max-width: 767px) {
  .legal-page-body ul {
    padding-left: 2rem;
  }
  .legal-page-body .content-cover {
    padding: 2rem 5% 3rem;
  }
} 
