.show-18-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  color: var(--main-font);
  z-index: 99999;
  display: block;
}
.popup-18-overlay {
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: .7;
}
.popup-18-body {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -250px;
  padding: 0 3rem;
  width: 28rem;
  height: 23rem;
  background-size: cover;
  background: linear-gradient(180deg, #FFB41C 0%, #FFC416 100%);  
}
.popup-18-body h2 {
  font-style: normal;
  margin: 4.5rem 0 20px;
  -webkit-text-stroke: 1.8px var(--black);
}
.checkbox-cover {
  position: relative;
  box-sizing: border-box;
}
.checkbox-cover,
.checkbox-cover a {
  font-style: normal;
  width: 100%;
  font-size: 1.3rem;
  line-height: 30px;
  color: var(--footer-font);
}
.checkbox-cover a {
  text-decoration: underline;
}
.checkbox-cover label {
  padding: 0 0 0 1.5rem;
  position: relative;
  cursor: pointer;
}
.checkbox-cover input {
  opacity: 0;
}
.box {
  position: absolute;
  width: 24px;
  height: 22px;
  border: none;
  border-radius: 4px;
  background-color: var(--footer-font);
  top: 16px;
  transform: translateY(-50%);
  left: 0px;
}
.box.checkbox {
  border-radius: 2px;
}
input:checked~.box.checkbox::after {
  border-radius: 2px;
}
input:checked~.box::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 22px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.7371 0.382073C17.617 0.261007 17.4741 0.164914 17.3167 0.0993378C17.1593 0.0337616 16.9905 0 16.82 0C16.6495 0 16.4806 0.0337616 16.3232 0.0993378C16.1658 0.164914 16.023 0.261007 15.9029 0.382073L6.27998 10.0179L2.23707 5.96207C2.11239 5.84164 1.96522 5.74694 1.80395 5.68339C1.64268 5.61983 1.47047 5.58866 1.29715 5.59166C1.12383 5.59466 0.952804 5.63177 0.793828 5.70086C0.634851 5.76996 0.491041 5.86969 0.370608 5.99437C0.250175 6.11904 0.155478 6.26621 0.0919226 6.42748C0.0283675 6.58875 -0.00280104 6.76097 0.00019752 6.93428C0.00319608 7.1076 0.0403028 7.27863 0.109399 7.4376C0.178494 7.59658 0.278226 7.74039 0.4029 7.86082L5.3629 12.8208C5.48298 12.9419 5.62584 13.038 5.78324 13.1036C5.94064 13.1691 6.10947 13.2029 6.27998 13.2029C6.4505 13.2029 6.61933 13.1691 6.77673 13.1036C6.93413 13.038 7.07699 12.9419 7.19707 12.8208L17.7371 2.28082C17.8682 2.15987 17.9728 2.01307 18.0444 1.84967C18.1159 1.68628 18.1529 1.50983 18.1529 1.33145C18.1529 1.15307 18.1159 0.976618 18.0444 0.813223C17.9728 0.649828 17.8682 0.503027 17.7371 0.382073Z' fill='black'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hidden {
  display: none;
}
.popup-18-body .buttons {
  display: flex;
  width: 100%;
  padding-top: 3rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}
.popup-18-body .buttons button {
  padding: 1rem 0;
  width: 13rem;
  text-align: center;
  font-family: "LuckiestGuy", sans-serif;
  font-size: 2.5rem;
  line-height: 25px;
  -webkit-text-stroke: 2px var(--black);
  color: var(--footer-font);
  text-shadow: 1px 4px 6px var(--btn), 0 0 0 #000, 1px 4px 6px var(--btn);
  text-transform: uppercase;
  background: var(--btn);
  border: 2px solid var(--black);
  transition: all .5s;
  cursor: pointer;
}
#button-yes {
  background: var(--btn);
}
#button-yes:hover,
#button-yes:focus {
  background: var(--btn-hover);
  text-shadow: 1px 4px 6px var(--btn-hover), 0 0 0 #000, 1px 4px 6px var(--btn-hover);
}
#button-no {
  background: var(--btn-no);
  text-shadow: 1px 4px 6px var(--btn-no), 0 0 0 #000, 1px 4px 6px var(--btn-no);
}
#button-no:hover,
#button-no:focus {
  background: var(--btn-no-hover);
  text-shadow: 1px 4px 6px var(--btn-no-hover), 0 0 0 #000, 1px 4px 6px var(--btn-no-hover);
}
.hide-18-popup {
  display: none;
}
.show-error,
.hide-error {
  font-size: 14px;
  color: var(--error) ;
  width: 100%;  
  position: absolute;
  top: 30px;
  left: 0;
}
.show-error {
  display: block;
}
.hide-error {
  display: none;
}
.no-age-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-age-bg {
  width: 13rem;
  height: auto;
  margin-top: 2rem;
}
.popup-18-body h2.age-no-title {
  margin: 1rem 0 0;
  font-size: 2.5rem;
  text-align: center;
}

/* RESPONSIVE */
@media (max-width: 767px) {
  .popup-18-body {
      top: 20vh;
      left: 4vw;
      margin-left: 0;
      width: 70vw;
      height: fit-content;
      padding-bottom: 2rem;
  }
  .popup-18-body h2 {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 3rem 0 15px;
  }
  .checkbox-cover label {
      font-size: 1.2rem;
      line-height: 1.5rem;
      padding: 0;
  }
  .checkbox-cover,
  .checkbox-cover a {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 3rem;
  }
  .box {
    width: 12px;
    height: 12px;
    top: 11px;
  }
  .popup-18-body .buttons {
    padding: 0;
    justify-content: space-around;
    margin-top: 18px;
  }
  .popup-18-body .buttons button {
    width: 110px;
    padding: 10px 0;
    font-size: 1.5rem;
  }
  .show-error, 
  .hide-error {
    font-size: 15px;
    top: 28px;
    left: 0;
    width: 100%;
  }
  input:checked~.box::after {
    width: 10px;
    height: 10px;
  }
}
