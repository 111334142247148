.about-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
}
.about-block-image-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 115%;
  z-index: 5;
}
.about-inner-block {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
}
.about-block-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.about-empty-image {
  width: 20%;
}
.about-block-image {
  width: 80%;
}
.about-block-image img {
  width: 100%;
  height: auto;
  margin-top: -1rem;
}
.about-image-mobile {
  display: none;
}
.about-block-text {
  position: relative;
  width: 45%;
}
.about-block-text p {
  margin: 2rem 0 4rem;
}
.about-block-game-icons-cover img {
  width: 70%;
  height: auto;
  margin: 1rem 0;
  border-radius: 20px;
}

/* RESPONSIVE */
@media (max-width: 1740px) {
  .about-block {
    padding-top: 5rem;
    margin-bottom: 10rem;
  }
}
@media (max-width: 1450px) {
  .about-block {
    padding-top: 10rem;
  }
}
@media (max-width: 1235px) {
  .about-block {
    padding-top: 4rem;
  }
}
@media (max-width: 1025px) {
  .about-block {
    flex-direction: column;
    margin: 2rem auto 0;
    padding-top: 0;
  }
  .about-inner-block {
    position: relative;
  }
  .about-empty-text,
  .about-empty-image {
    display: none;
  }
  .about-block-text,
  .about-block-image {
    width: 100%;
  }
  .about-block-text p {
    margin: 1rem 0 2rem;
  }
  .about-block-image img {
    width: 115%;
    height: auto;
    margin: 0 0 -5rem -30%;
  }
}
@media (max-width: 767px) {
  .about-block {
    margin-top: 0;
  }
  .about-block-image img {
    margin: 0 0 0 -30%;
  }
  .about-btn {
    margin-top: 2rem;
  }
}

