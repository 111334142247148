.game-cover {
    margin-bottom: 5rem;
}
.game-cover iframe {
    width: 100%;
    height: 750px;
}
.game-headline {
    font-size: 25px;
}
.game-back-link {
    float: left;
    color: var(--main-font);
    margin: 26px 0;
    text-decoration: underline;
    cursor: pointer;
}
.game-back-link i {
    margin-right: 5px;
}

/* RESPONSIVE */
@media (max-width: 767px) {
    .game-cover iframe {
        height: 500px;
    }
    .game-cover {
        margin-bottom: 100px;
    }
}
@media (max-width: 600px) {
    .game-cover iframe {
        height: 410px;
    }
}
@media (max-width: 500px) {
    .game-cover iframe {
        height: 350px;
    }
}
@media (max-width: 440px) {
    .game-cover iframe {
        height: 280px;
    }
}
@media (max-width: 380px) {
    .game-cover iframe {
        height: 220px;
    }
}
