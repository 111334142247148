.contact-form-cover form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-form-cover label {
  position: relative;
  width: 100%;
}
.contact-form-cover .title {
  margin-bottom: 2rem;
}
.contact-form-cover input,
.contact-form-cover textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-family: "PaytoneOne";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  border: 2px solid var(--main-font);
  color: var(--main-font);
  background-color: #FFCC4B;
  box-sizing: border-box;
}
.contact-form-cover textarea {
  max-height: 250px;
  min-height: 150px;
  resize: vertical;
}
.contact-form-cover input::placeholder,
.contact-form-cover textarea::placeholder {
  color: #3E392A;
  opacity: .94;
}
#name_error,
#email_error {
  top: 60px;
}
#message_error {
  bottom: 10px;
}
.error_message-show {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 15px;
  color: var(--error);
  font-weight: 700;
  font-size: 14px;
}
.error_message-hide {
  display: none;
}
.contact-form-cover .hide-form {
  display: none;
}
.show-success-message {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: var(--main-font);
  text-align: center;
}
.hide-success-message {
  display: none;
}

/* RESPONSIVE */
@media (max-width: 1025px) {
  .contact-mobile-btn {
    padding: 0.5rem 4.5rem;
}
}
@media (max-width: 767px) {
  .contact-mobile-btn {
    width: 100%;
  }
  .contact-form-cover input {
    margin-bottom: 2rem;
  }
  #message_error {
    bottom: 7px;
  }
}

