:root {
  --main-bg: #ffc417;
  --footer: #844d41;
  --header: #2d2d2d;
  --main-font: #000;
  --footer-font: #ffffff;
  --btn: #d48655;
  --btn-hover: #f53a30;
  --btn-no: #7b7b7b;
  --btn-no-hover: #4d4d4d;
  --btn-signin: #ffb31c;
  --black: #000;
  --error: #f53a30;
  --popup: #ffbe18;
}

/* FONTS */
@font-face {
  font-family: "LuckiestGuy";
  font-display: block;
  src: url("/assets/fonts/LuckiestGuy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PaytoneOne";
  font-display: block;
  src: url("/assets/fonts/PaytoneOne-Regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "PaytoneOne", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-font);
  background-color: var(--main-bg);
}

a {
  color: var(--main-font);
}
.home-page-cover {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  /* padding: 0 0 16.7rem; */
}
.main-container {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.text-page-body {
  height: 100%;
  margin-top: 7.45rem;
}
.title {
  font-family: "LuckiestGuy", sans-serif;
  font-size: 3rem;
  line-height: 3.3rem;
  text-transform: uppercase;
  -webkit-text-stroke: 1px var(--black);
  color: var(--btn);
}
p {
  font-family: "PaytoneOne", sans-serif;
  font-size: 1.375rem;
  line-height: 1.7rem;
}
.banner-title {
  transition: color 0.3s;
}
.banner-title-hover {
  color: var(--btn-hover);
}
.main-btn {
  padding: 0.5rem 2.5rem;
  font-family: "LuckiestGuy", sans-serif;
  font-size: 3.625rem;
  line-height: 4rem;
  -webkit-text-stroke: 2px var(--black);
  color: rgba(255, 203, 47, 0.8);
  text-shadow: 1px 4px 6px var(--btn), 0 0 0 #000, 1px 4px 6px var(--btn);
  text-transform: uppercase;
  background: var(--btn);
  border: 2px solid var(--black);
  transition: all 0.3s;
  cursor: pointer;
}
.main-btn:hover {
  background: var(--btn-hover);
  text-shadow: 1px 4px 6px var(--btn-hover), 0 0 0 #000, 1px 4px 6px var(--btn-hover);
}
.game-image-block {
  position: relative;
}
.game-image-block .hover-img {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 0.5s;
}
.game-image-block:hover .hover-img {
  opacity: 1;
}
.game-image-block:hover img {
  filter: none;
}
.game-image-block button,
.play-game-btn {
  padding: 0.5rem 2rem;
  font-family: "LuckiestGuy", sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
  -webkit-text-stroke: 2px var(--black);
  color: rgba(255, 203, 47, 0.8);
  text-shadow: 1px 4px 6px var(--btn), 0 0 0 #000, 1px 4px 6px var(--btn);
  text-transform: uppercase;
  background: var(--btn);
  border: 2px solid var(--black);
  transition: all 0.5s;
  cursor: pointer;
}
.play-game-btn {
  display: none;
  padding: 0.5rem 0rem;
}
.game-image-block:hover button {
  opacity: 1;
}

.game-image-block button:hover {
  background: var(--btn-hover);
  text-shadow: 1px 4px 6px var(--btn-hover), 0 0 0 #000, 1px 4px 6px var(--btn-hover);
}
.sclick-id-style {
  position: absolute;
  width: 0;
  height: 0;
  border: 0;
  display: none;
}

/* RESPONSIVE */
@media (max-width: 1350px) {
  .main-container {
    max-width: 1100px;
  }
}
@media (max-width: 1025px) {
  .main-container {
    width: 95%;
    max-width: none;
  }
  .about-block {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
  .main-container {
    width: auto;
    max-width: -webkit-fill-available;
    padding: 0 4%;
    margin: 0 auto;
  }
  .title {
    color: var(--btn-hover);
  }
  .main-btn,
  .game-image-block button {
    background: var(--btn-hover);
    text-shadow: 1px 4px 6px var(--btn-hover), 0 0 0 #000, 1px 4px 6px var(--btn-hover);
  }
  p {
    width: auto;
  }
}
