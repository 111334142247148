.thank-you-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    color: var(--main-font);
    z-index: 99999;
    top: 0;
    left: 0;
    display: block;
}

.thank-you-overlay {
    width: 100%;
    height: 100%;
    background-color: var(--main-font);
    opacity: .7;
}

.thank-you-body {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-height: 748px;
    height: 90%;
    background-color: var(--main-bg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0% 100%;
}

.thank-you-popup .thank-you-body h2 {
    font-size: 3.75rem;
    line-height: 130%;
    margin: 3rem 0 10px;
    text-align: center;
}
.thank-you-popup .thank-you-body p{
    width: 90%;
    max-width: 33.375rem;
    margin: 0 auto;
    text-align: center;
    font-family: "LuckiestGuy", sans-serif;
    font-size: 2.25rem;
    -webkit-text-stroke: 1px var(--black);
    color: #fff;
    line-height: 130%;
    letter-spacing: 0.05em;
}

.thank-you-bg {
    position: absolute;
    bottom: 0;
    width: 80%;
    max-width: 25.625rem;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 864px) and (max-height: 415px){
    .thank-you-popup .thank-you-body h2 {
        margin: 2rem 0 10px;
        font-size: 2.75rem;
        line-height: 110%;
    }
    .thank-you-popup .thank-you-body p {
        font-size: 1.5rem;
        line-height: 109%;
    }
    .thank-you-bg {
        max-width: 9.625rem;
    }
}

    /* RESPONSIVE */
 @media (max-width: 770px) {
     .thank-you-body {
         width: 90%;
         height: 450px;
     }
     .thank-you-popup .thank-you-body h2 {
         margin: 2rem 0 10px;
         font-size: 2.75rem;
         line-height: 110%;
     }
    .thank-you-popup .thank-you-body p {
        font-size: 18px;
        line-height: 26px;
    }
     .thank-you-bg {
         max-width: 18.625rem;
     }
}

/*@media (max-width: 380px) {*/
/*    .thank-you-body {*/
/*        height: 100vw;*/
/*    }*/
/*    .thank-you-popup .thank-you-body h2,*/
/*    .thank-you-popup .thank-you-body h3 {*/
/*        font-size: 24px;*/
/*        line-height: 28px;*/
/*    }*/
/*    .thank-you-popup .thank-you-body p {*/
/*        font-size: 15px;*/
/*        line-height: 22px;*/
/*    }*/
/*} */
