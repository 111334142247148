.contact-body {
    background-image: url('/assets/images/contact-background.webp');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: top right;
    margin-top: 7.45rem;
}
.contact-image-container {
    position: relative;
    width: auto;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.contact-form-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
}
.contact-form-cover .main-container {
    margin-top: 8rem;
    height: calc(100vh - 18rem);
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-form {
    width: 23rem;
    padding-top: 20%;
}
.empty-form-block {
    width: 55%;
}
.empty-image-block {
    width: 35%;
}
.contact-image-cover {
    width: 65%;
    height: 100%;
    min-height: calc(100vh - 18rem);
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.contact-image-cover img {
    width: 50vh;
}
.text-page-body.contact-page-bg {
    background-position: center right;
}

/* RESPONSIVE */
@media (max-width: 1025px) {
    .contact-body {
        background-image: none;
    }
    .contact-mobile-container {
        display: flex;
        flex-direction: column-reverse;
        background-image: url('/assets/images/contact-background-mobile.webp');
        background-size: 100%;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    .contact-form-cover .main-container {
        margin-top: 10rem;
        height: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .contact-form-cover {
        position: relative;
        width: 100%;
    }
    .contact-form {
        width: 50vw;
        padding-top: 0;
    }
    .contact-image-container {
        margin-top: -7rem;
    }
    .contact-image-cover {
        width: 100%;
        height: 100%;
        min-height: auto;
        margin-top: 10rem;
    }
    .contact-image-cover img {
        width: 40vh;
    }
}
@media (max-width: 767px) {
    .contact-mobile-container {
        background-image: none;
    }
    .contact-form {
        width: 100%;
        text-align: center;
    }
}
