.first-screen-banner {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 6;
}
.display-banner {
  height: 100%;
  width: 110%;
  display: inline;
  z-index: -1;
}
.mobile-banner {
  cursor: pointer;
  display: none;
}
.banner-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  width: 85%;
  height: auto;
}
.banner-img-container {
  width: 60%;
}
.banner-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 100%;
  color: var(--white);
}
.empty-text-banner {
  width: 40%;
}
.banner-text-block {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 10%;
}
.banner-text,
.banner-btn,
.banner-title {
  margin: 1rem 0;
}
.banner-btn {
  width: fit-content;
}
.first-screen-mobile {
  position: absolute;
  height: 100%;
  width: 100%;
}
.tablet-banner,
.mobile-banner {
  display: none;
}

/* RESPONSIVE */
@media (max-width: 1350px) {
  .display-banner {
    width: 130%;
  }
}
@media (max-width: 1025px) {
  .banner-container {
    position: relative;
  }
  .banner-text-container {
    position: relative;
    margin-top: 8rem;
  }
  .banner-title {
    width: 70%;
  }
  .banner-text {
    width: 85%;
  }
  .first-screen-banner {
    flex-direction: column;
    margin-top: 1rem;
  }
  .first-screen-mobile {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  .display-banner,
  .mobile-banner {
    display: none;
  }
  .tablet-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
  .empty-text-banner,
  .empty-banner {
    display: none;
  }
  .banner-container,
  .banner-text-block {
    width: 100%;
    padding-left: 0;
  }
  .banner-text {
    width: auto;
  }
  .banner-img-container {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .banner-title {
    width: 100%;
  }
  .display-banner,
  .tablet-banner {
    display: none;
  }
  .mobile-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
  .banner-img-container {
    width: 90%;
  }
}
@media (orientation: landscape) and (max-height: 400px) {
  /* .first-screen-banner img {
    max-height: none;
    height: 150vh;
  } */
}
