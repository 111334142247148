.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    /*flex-direction: column;*/
}
.form-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #9E9E9E;
    border-radius: 6px;
    height: 300px;
    max-width: 350px;
    width: 100%;
}
.authentication-form {
    display: block;
    width: 80%;
    /*max-width: 355px;*/
}
.authentication-form label {
    width: 100%;
    position: relative;
}
.authentication-form input,
.auth-main-btn{
    display: block;
    width: 100%;
    margin: 15px 0;
    padding: 3px;
}
.auth-main-btn {
    cursor: pointer;
    transition: all .3s;
    transform: scale(1);
}
.auth-main-btn:hover {
    transform: scale(1.02);
}
.auth-error_message {
    font-family: monospace;
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 15px;
    color: var(--error);
    font-weight: 200;
    font-size: 14px;
    bottom: -14px;
}