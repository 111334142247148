.sign-up-popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    color: var(--footer-font);
    z-index: 999;
    display: block;
}

.sign-up-overlay {
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: .7;
}

.sign-up-body {
    position: absolute;
    background-color: var(--popup);
    background-position: center -27px;
    top: 12%;
    left: 50%;
    margin-left: -270px;
    width: 520px;
    min-height: 420px;
    background-size: cover;
    border: none;
}

.sign-up-popup .sign-up-body h2 {
    margin: 3rem 0 2rem;
    font-size: 2rem;
    line-height: 2.3rem;
    text-align: center;
}

.sign-up-body form {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
}

.terms-checkbox-cover {
    position: relative;
    padding: 2px 0 20px;
    margin-top: -.7rem;
}

.sign-up-body .box {
    position: absolute;
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 4px;
    background-color: var(--footer-font);
    top: 14px;
    transform: translateY(-50%);
    left: 0px;
}

#terms,
#mailer {
    display: none;
}

.checkbox-text {
    margin-left: 30px;
    cursor: pointer;
    color: var(--footer-font);
    font-size: 14px;
    line-height: 22px;
}

.checkbox-text a {
    color: var(--footer-font);
    text-decoration: underline;
}

.input-cover {
    position: relative;
}

.sign-up-body .box.checkbox {
    border-radius: 2px;
    cursor: pointer;
}

.sign-up-body input:checked~.box.checkbox::after {
    border-radius: 2px;
}

.sign-up-body input:checked~.box::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.7371 0.382073C17.617 0.261007 17.4741 0.164914 17.3167 0.0993378C17.1593 0.0337616 16.9905 0 16.82 0C16.6495 0 16.4806 0.0337616 16.3232 0.0993378C16.1658 0.164914 16.023 0.261007 15.9029 0.382073L6.27998 10.0179L2.23707 5.96207C2.11239 5.84164 1.96522 5.74694 1.80395 5.68339C1.64268 5.61983 1.47047 5.58866 1.29715 5.59166C1.12383 5.59466 0.952804 5.63177 0.793828 5.70086C0.634851 5.76996 0.491041 5.86969 0.370608 5.99437C0.250175 6.11904 0.155478 6.26621 0.0919226 6.42748C0.0283675 6.58875 -0.00280104 6.76097 0.00019752 6.93428C0.00319608 7.1076 0.0403028 7.27863 0.109399 7.4376C0.178494 7.59658 0.278226 7.74039 0.4029 7.86082L5.3629 12.8208C5.48298 12.9419 5.62584 13.038 5.78324 13.1036C5.94064 13.1691 6.10947 13.2029 6.27998 13.2029C6.4505 13.2029 6.61933 13.1691 6.77673 13.1036C6.93413 13.038 7.07699 12.9419 7.19707 12.8208L17.7371 2.28082C17.8682 2.15987 17.9728 2.01307 18.0444 1.84967C18.1159 1.68628 18.1529 1.50983 18.1529 1.33145C18.1529 1.15307 18.1159 0.976618 18.0444 0.813223C17.9728 0.649828 17.8682 0.503027 17.7371 0.382073Z' fill='black'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sign-up-body .hidden {
    display: none;
}

.sign-up-body label {
    margin: 0;
    font-style: normal;
    font-size: 16px;
    display: flex;
    margin: 0 3px;
    align-items: center;
    color: #E4ECF9;
    line-height: 24px;
}

.intl-tel-input .flag-container .arrow {
    color: #1f1f1f;
}

.intl-tel-input {
    width: 100%;
}

.intl-tel-input input .intl-tel-input input[type=tel] {
    margin-bottom: 30px !important;
}

.intl-tel-input .country-list {
    z-index: 12;
}
.intl-tel-input .country-list .country .dial-code,
.intl-tel-input .country-list {
    color: var(--main-font);
}

.sign-up-body form .input-cover {
    margin: 0 0 27px;
}

.sign-up-body form .input-cover input {
    width: 100%;
    padding: 20px 52px;
    box-sizing: border-box;
    height: 15px;
    font-family: "PaytoneOne", sans-serif;
    display: flex;
    align-items: center;
    color: #2C2B2B;
    border: 2px solid var(--main-font);
    outline: none;
}
.sign-up-body form .input-cover input::placeholder {
  font-family: "PaytoneOne", sans-serif;
  color: #2C2B2B;
}
.sign-up-body #register,
.sign-up-body #login {
    display: block;
    margin: 0 auto;
    padding: .5rem 4rem;
    font-size: 2.25rem;
    line-height: 2.75rem;
    -webkit-text-stroke: 1px var(--main-font);
}
.sign-up-body #register.disable {
    opacity: .7;
    cursor: wait;
}
.form-text,
.form-text button {
    max-width: 350px;
    margin: 1rem auto;
    text-align: center;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: var(--footer-font);
}

.form-text button {
    padding: 0;
    text-decoration: underline;
    font-family: "PaytoneOne", sans-serif;
    color: var(--footer-font);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close-popup-btn {
    position: absolute;
    top: 10px;
    right: 5%;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close-popup-btn i {
    color: #fff;
    font-size: 36px;
}

.error_message-show {
    display: block;
}

.error_message-show {
    font-size: 12px;
    color: var(--error);
}

.error_message-hide {
    display: none;
}

.sign-up-body form #password_error {
    top: 45px;
}
.sign-up-body form #email_error {
    position: relative;
    top: 2px;
    margin-bottom: -5px;
    min-height: 5px;
}
#mailer_error, 
#terms_error {
    top: 22px;
    left: 33px;
    font-size: 12px;
}
#fullname_error,
#phone_error {
    top: 48px;
}

.sign-up-body form .input-cover .error_input-hide {
    border: initial;
}

.sign-up-body form .input-cover .error_input-show {
    border: 2px solid var(--error);
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

@media (max-width: 780px) {
    .sign-up-body {
        margin-left: -240px;
        width: 480px;
    }
    #mailer_error, #terms_error {
        top: 45px;
        left: 20px;
    }
}

@media (max-width: 650px) {
    .sign-up-body {
        margin-left: -200px;
        width: 400px;
        min-height: 410px;
    }
    .sign-up-popup .sign-up-body h2 {
        font-size: 27px;
        line-height: 30px;
    }
    .sign-up-body form .input-cover input {
        color: #353535;
        padding: 15px;
        width: 98%;
    }
    .sign-up-body label {
        font-size: 14px;
    }
    .sign-up-body #register,
    .sign-up-body #login {
        padding: 8px 0;
        width: 98%;
        font-size: 16px;
    }
    .form-text,
    .form-text button {
        font-size: 14px;
        margin: 5px 0;
    }
    .sign-up-body form #email_error,
    .sign-up-body form #password_error,
    .sign-up-body form #fullname_error,
    .sign-up-body form #phone_error {
        top: 64px;
        font-size: 14px;
        font-weight: 600;
    }
    #terms_error {
        top: 33px;
        font-size: 14px;
        font-weight: 600;
    }
    .close-popup-btn {
        right: 5px;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 450px) {
    .sign-up-body form .input-cover input {
        padding: 20px 10px;
        font-size: 1.1rem;
    }
    .sign-up-body {
        margin-left: -150px;
        width: 280px;
        min-height: 310px;
    }
    .sign-up-body form {
        max-width: none;
        width: 90%;
    }
    .sign-up-popup .sign-up-body h2 {
        margin: 20px 0 0;
    }
    .sign-up-body label {
        font-size: 12px;
        margin: 0;
    }
    .sign-up-body form #email_error,
    .sign-up-body form #password_error,
    .sign-up-body form #fullname_error,
    .sign-up-body form #phone_error {
        top: 47px;
        font-size: 12px;
    }
    .sign-up-body form #email_error {
        top: 4px;
        font-size: 12px;
    }
    #terms_error {
        top: 24px;
        font-size: 12px;
    }
    .sign-up-body .box {
        width: 12px;
        height: 12px;
        top: 14px;
    }
    .checkbox-text {
        margin-left: 20px;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .sign-up-popup .sign-up-body h2 {
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin-bottom: 1rem;
    }
    .sign-up-body #register,
    .sign-up-body #login {
        padding: 5px 0;
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
    .form-text,
    .form-text button {
        font-size: .8rem;
        margin: 5px auto 0.8rem;
        padding-left: 0;
        text-align: left;
        max-width: 250px;
    }
    .form-text button {
        padding-left: 0;
    }
    .sign-up-body {
        top: 17%;
        left: 4vw;
        width: 92vw;
        margin: 0;
    }
}
