.top10-page-body {
  position: relative;
}
.top10-main-container {
  width: 80%;
  margin: 7rem auto 2rem;
  padding-top: 4rem;
  text-align: center;
}
.top10-games-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.top10-games-cover {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
}
.top10-games-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}
.top10-games-container .top10-games-image-block {
  width: 30%;
}
.top10-games-middle-container .top10-games-image-block {
  width: 25%;
}
.top10-games-container img{
  width: 100%;
  height: auto;
}

/* RESPONSIVE */
@media (max-width: 767px) {
  .top10-games-container {
    flex-direction: column;
    margin-bottom: 0;
  }
  .top10-games-container .top10-games-image-block {
    width: 90%;
    margin: 1rem 0;
  }
} 
