.header-section {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: var(--header);
  color: var(--footer-font);
}
.header-section-cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px auto 0;
  /* padding: 10px 5% 20px 5%; */
  padding: 10px 0 20px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.header-section-disclaimer {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 50px;
  width: 100%;
  background-color: #010506;
  color: var(--footer-font);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}
.logo {
  top: 10px;
  left: 20px;
  height: auto;
}
.nav-list-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 0;
}
.nav-list-section li {
  list-style: none;
}
.nav-list-section li a {
  position: relative;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: var(--footer-font);
  text-decoration: none;
  transition: all 0.2s linear;
}
.nav-list-section li a:hover {
  color: var(--main-bg);
}
.header .active {
  font-weight: 600;
}
.btns-container {
  display: flex;
  justify-content: space-between;
  width: 22%;
}
.login-btn,
.register-btn,
.lp-page.register-btn {
  /* width: 40%; */
  padding: 0.5rem 1.5rem;
  font-family: "LuckiestGuy", sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  border: none;
  outline: none;
  color: var(--footer-font);
  background: var(--btn-signin);
  transition: all 0.3s linear;
  cursor: pointer;
}
.login-btn {
  margin-left: 1rem;
  border: 1px solid var(--main-bg);
  outline: none;
  background: transparent;
}
.register-btn:hover,
.lp-page.register-btn:hover {
  background: #fde934;
}
.login-btn:hover {
  background-color: rgba(217, 217, 217, 0.2);
  border: 1px solid #ffff54;
  outline: 1px solid #ffff54;
}
.mobile-menu-btn {
  width: 70px;
  height: 70px;
  background-color: transparent;
  color: var(--footer-font);
  display: none;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 100%;
  cursor: pointer;
  z-index: 21;
}
.nav-logo-mobile {
  display: none;
}

.cookie-popup-link {
  text-decoration: underline;
  cursor: pointer;
}
/* RESPONSIVE */
@media (max-width: 1350px) {
  .login-btn,
  .register-btn,
  .lp-page.register-btn {
    font-size: 1.1rem;
  }
  .nav-list-section li a {
    font-size: 1rem;
  }
}
@media (max-width: 1125px) {
  .header-section-cover {
    width: 95%;
    padding: 1rem 0;
  }
  .mobile-menu-btn {
    display: flex;
    font-size: 24px;
  }
  .mobile-menu-btn img {
    width: 30px;
  }
  .login-btn,
  .register-btn {
    display: initial;
    margin: 0;
  }
  .register-btn.lp-page {
    display: initial;
    width: 126px;
    padding: 8px 0px;
    font-size: 20px;
  }
  .nav-list-section {
    width: 100%;
    background: #0c1729;
    flex-direction: column;
    position: absolute;
    top: -360px;
    transition: all 0.3s ease-in-out;
  }
  .nav-list-section.show-mobile-menu {
    top: -10px;
    margin: 0;
    width: 100.5vw;
    margin-left: -3vw;
    padding: 1rem 0;
    background-color: var(--header);
    z-index: 20;
  }
  .nav-list-section li {
    margin: 10px 0;
  }
  .logo {
    z-index: 15;
  }
  .nav-logo-mobile {
    width: 80%;
  }
  .header-disclaimer {
    font-size: 11px;
    line-height: 13px;
    height: 7vh;
  }
}

@media (max-width: 767px) {
  .header-section-cover {
    width: 100%;
    padding: 1rem 5%;
  }
  .nav-list-section {
    width: 90%;
  }
  .nav-list-section.show-mobile-menu {
    margin-left: -5.5vw;
  }
}

@media (max-width: 440px) {
  .mobile-menu-btn {
    width: 40px;
    height: 40px;
  }
  .mobile-menu-btn {
    right: 10px;
    top: 14px;
  }
}
