@font-face {
    font-family: "PaytoneOne";
    font-display: block;
    src: url("/public/assets/fonts/PaytoneOne-Regular.ttf") format("truetype");
}
/* categories menu start */

.categories-menu-cover {
    position: sticky;
    top: 40px;
    width: 100%;
    z-index: 10;
    background: #ffc316;
    border-radius: 10px;
}

.categories-menu {
    width: 100%;
    max-width: 954px;
    margin: 0 auto;
}

.categories-menu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    box-sizing: border-box;
}

.categories-menu li a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 10px;
    padding: 10px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    transition: all .3s linear;
}


.categories-menu li .img-div{
    width: 102px;
    height: 102px;
    background: #D48655;
    border: 1px solid #000000;
    border-radius: 15px;
    position: relative;
}
.categories-menu li a:hover .img-div,
.categories-menu li a.active .img-div{
    background: #F53A30;
}
.categories-menu li a img {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.categories-menu li a span:last-child{
    font-family: 'PaytoneOne';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #4B5055;
    white-space: nowrap;
    margin-top: 10px;
}


/* categories menu end */

.games-label {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto 60px;
    height: 11px;
    background: #D48655;
    border: 1px solid #000000;
    box-sizing: border-box;
}

.games-label img {
    margin-right: 15px;
}

.games-label span {
    font-family: "LuckiestGuy", sans-serif;
    font-size: 2.25rem;
    line-height: 3.3rem;
    text-transform: uppercase;
    -webkit-text-stroke: 1px var(--black);
    color: var(--btn);
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 0 15px;
    background: #ffc316;
}

.all-games-content,
.title-block{
    width: 100%;
    max-width: 1049px;
    margin: 0 auto;
    padding: 55px 40px 20px;
    box-sizing: border-box;
}
.title-block{
    padding: 20px 20px 70px;
}
.title-block .all-games{
    position: relative;
    font-size: 3.25rem;
    margin: auto;
}
.title-block .all-games:before{
    content: '';
    background-image: url(../images/title-left.png);
    position: absolute;
    top: -100px;
    left: -210px;
    width: 255px;
    height: 215px;
    background-repeat: no-repeat;
}
.title-block .all-games:after{
    content: '';
    background-image: url(../images/title-right.png);
    position: absolute;
    top: -100px;
    right: -210px;
    width: 255px;
    height: 215px;
    background-repeat: no-repeat;
}

.steps-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lp-step {
    position: relative;
    width: 30%;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 2px 2px 3px #242424;
    background-color: #4383FF;
    padding: 40px 30px 40px 80px;
}

.lp-step img {
    position: absolute;
    left: -10px;
}

.lp-step h3 {
    position: absolute;
    top: 55px;
    left: 7px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 43px;
    text-align: center;
    color: #3671D4;
}

.lp-step h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
}

.lp-step p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.lp-play-now-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.lp-play-now-block .arrow-down {
    margin: 30px 0;
}

.lp-play-game-btn {
    width: 250px;
    padding: 20px 0;
    background: #0D5EFC;
    border-radius: 10px;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #FFD600;
    cursor: pointer;
    transition: all .3s linear;
    box-shadow: 2px 2px 3px #242424;
    text-decoration: none;
}

.lp-play-game-btn:hover {
    background: #033cad;
}

.lp-play-now-block .text {
    background: rgba(51, 120, 255, 0.18);
    border-radius: 10px;
    padding: 30px 50px;
    box-sizing: border-box;
    margin: 50px 0;
}

.yellow-border {
    border: 1px solid #FFD600;
}

.lp-games .all-game-icons-cover {
    width: 22%;
    position: relative;
    margin: 0 auto 30px;
    max-width: 230px;
}

.lp-play-now-block .text p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.lp-games-cover h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #F6F4F4;
}

.lp-games {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px 10px;
}

.all-games-content .lp-games {
    margin-bottom: 40px;
}

.all-game-icons-cover {
    position: relative;
    margin-bottom: 50px;
}
.all-game-icons-cover img {
    width: 100%;
}
.all-game-icons-cover .play-game-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 2rem;
}

.all-game-icons-cover:hover .play-game-btn {
    display: block;
}

.paralax-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 0;
    box-sizing: border-box;
}

.lp-paralax-img {
    position: relative;
    margin: 0 1%;
}

.lp-paralax-img img {
    border-radius: 10px;
    width: 100%;
}

.lp-paralax-img h3 {
    position: absolute;
    top: -10px;
    width: 100%;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.lp-paralax-img p {
    position: absolute;
    width: 100%;
    margin: 20px auto;
    top: 40px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #F6F4F4;
}

.lp-page-carousel {
    width: 100%;
    max-width: 1280px;
    margin: 100px auto 50px;
    padding: 0 40px;
    box-sizing: border-box;
}

.lp-page-carousel h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 44px;
    text-align: center;
    color: #F6F4F4;
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
}

.lp-owl-text {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2670FF;
    text-align: center;
    border-radius: 10px;
    box-shadow: 2px 2px 3px #242424;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #F6F4F4;
}

@media only screen and (max-width: 1200px) {
    .lp-step {
        min-height: 260px;
        padding: 30px 20px 30px 80px;
    }
    .lp-step img {
        top: 51px;
    }
    .lp-step h3 {
        top: 67px;
    }
    .categories-menu li .img-div{
        width: 82px;
        height: 82px;
    }
    .categories-menu li a img {
        /*width: 20px;*/
    }
    .categories-menu li a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1000px) {
    .categories-menu ul {
        flex-wrap: wrap;
    }
    .title-block .all-games:before{
        top: 0px;
        left: -150px;
        background-repeat: no-repeat;
        width: 56%;
        height: 100%;
        background-position: 50% 50%;
        background-size: contain;
    }
    .title-block .all-games:after{
        top: 0px;
        right: -150px;
        background-repeat: no-repeat;
        width: 56%;
        height: 100%;
        background-position: 50% 50%;
        background-size: contain;
    }

    .lp-step {
        min-height: 260px;
        padding: 15px 15px 20px 63px;
    }
    .lp-step img {
        left: -22px;
    }
    .lp-step h3 {
        left: -6px;
    }
    .lp-step h2 {
        font-size: 24px;
    }
    .lp-paralax-img h3 {
        font-size: 25px;
    }
    .lp-paralax-img p {
        top: 30px;
        font-size: 14px;
        text-shadow: 2px 2px 2px #242424;
    }
    .lp-games .all-game-icons-cover {
        width: 30%;
    }
    .categories-menu li a {
        flex-direction: column;
    }
    .categories-menu li a img {
        margin-right: 0;
        margin-bottom: 3px;
    }
}

@media only screen and (max-width: 800px) {
    .steps-cover {
        margin-top: 30px;
        flex-direction: column;
    }
    .lp-step {
        min-height: auto;
        padding: 30px 15px 20px 15px;
        width: 100%;
        margin-bottom: 40px;
    }
    .lp-step img {
        transform: rotate(90deg);
        top: -29px;
        width: 50px;
        left: 50%;
        margin-left: -25px;
    }
    .lp-step h2 {
        text-align: center;
        margin: 20px 0 0px;
    }
    .lp-step h3 {
        top: -34px;
        font-size: 28px;
        left: 50%;
        width: 30px;
        margin-left: -15px;
    }
    .lp-step p {
        text-align: center;
    }
    .paralax-content {
        flex-direction: column;
        padding: 50px 0;
    }
    .lp-paralax-img {
        margin: 30px 0;
    }
    .lp-page-cover {
        padding-bottom: 400px;
    }
    .lp-games .all-game-icons-cover {
        width: 45%;
    }
    /*.categories-menu li a {*/
    /*    margin: 0 3px;*/
    /*}*/
    .categories-menu ul {
        justify-content: space-around;
    }
}

@media only screen and (max-width: 640px) {
    .title-block .all-games:before,
    .title-block .all-games:after{
        display: none;
        /*top: 3px;*/
        /*width: 56%;*/
        /*height: 100%;*/
        /*background-position: 50% 50%;*/
        /*background-size: contain;*/
    }
    .title-block .all-games:before{
        left: -100px;
    }
    .title-block .all-games:after{
        right: -100px;
    }
    .lp-step {
        padding: 30px 15px 5px 15px;
        margin-bottom: 30px;
    }
    .lp-step img {
        transform: rotate( 90deg);
        top: -30px;
        width: 40px;
        left: 50%;
        margin-left: -20px;
    }
    .lp-step h3 {
        top: -39px;
        font-size: 22px;
    }
    .lp-step h2 {
        line-height: 19px;
        font-size: 22px;
        margin: 10px 0 0px;
    }
    .lp-step p {
        margin: 10px 0 10px;
    }
    .lp-play-now-block .text p {
        font-size: 16px;
    }
    .lp-games-cover h2 {
        font-size: 24px;
    }
    .lp-page-carousel h2 {
        font-size: 26px;
        line-height: 38px;
    }
    .lp-page-cover {
        padding-bottom: 500px;
    }
    .lp-games .all-game-icons-cover {
        width: 90%;
    }
    .categories-menu li a {
        font-size: 12px;
    }
    .games-label span {
        font-size: 1.5rem;
    }
    .categories-menu li a {
        padding: 10px 3px 5px;
    }
}

@media only screen and (max-width: 440px) {
    .title-block {
        padding: 20px 20px;
    }
    .title-block .all-games {
        font-size: 2rem;
        line-height: 2.3rem;
        /*width: 70%;*/
    }

    .categories-menu ul {
        justify-content: left;
        flex-wrap: wrap;
    }
    .categories-menu ul li{
        width: 33%;
        display: block;
        margin: 0 auto;
    }
    .all-games-content {
        padding: 30px 40px 10px;
    }
    .lp-play-now-block .text {
        padding: 30px;
    }
    .lp-games-cover h2 {
        line-height: 36px;
    }
    .all-game-icons-cover {
        margin-bottom: 20px;
    }
    .lp-page-carousel {
        margin: 50px auto;
    }
    .lp-page-carousel h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .lp-owl-text {
        height: 120px;
    }
    .lp-play-now-block .arrow-down {
        margin: 20px 0;
    }
    .categories-menu li .img-div {
        width: 50px;
        height: 50px;
    }
    .categories-menu li a {
        text-align: center;
        font-size: 15px;
        line-height: 20px;
    }

    .categories-menu li a img {
        width: 25px;
        height: auto;
    }
    .categories-menu li a span:last-child {
        font-size: 13px;
        margin-top: 0px;
    }
    .games-label {
        margin: 0 auto 35px;
    }
}

@media only screen and (max-width: 360px) {
    .games-label {
        width: 100%;
        margin: 0 auto 40px;
    }
    .lp-step {
        padding: 23px 10px 5px 9px;
        margin-bottom: 22px;
    }
    .categories-menu li a {
        font-size: 13px;
    }
    .categories-menu li a img {
        width: 25px;
    }
}