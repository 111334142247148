.footer {
    position: relative;
    width: 100%;
    padding: 20px 0 0;
    background-color: var(--footer);
    color: var(--footer-font);
    z-index: 100;
}
/* .footer.home-footer {
    position: absolute;
    bottom: 0;
} */
.footer-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.footer-cover {
    width: calc(100% - 7rem);
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
}
.footer-logo-container {
    width: 11rem;
}
.footer-logo {
    width: 100%;
    height: auto;
}
/* .empty-footer-block {
    width: 10%;
} */
.footer-content {
    width: fit-content;
}
.footer-cover-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin: 10px 0;
    text-align: left;
}
.footer p {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    max-width: 800px;
}
.footer-nav {
    margin: 30px 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.footer-nav li {
    list-style: none;
}
.footer-nav li a {
    color:  var(--footer-font);
    text-decoration: none;
    margin: 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.04em;
}
.footer-nav li a:hover {
    text-decoration: underline;
}
.footer-nav li:first-child a {
    margin-left: 0;
}

/* RESPONSIVE */
@media (max-width: 1025px) {
    .footer-inner-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 95%;
        padding-bottom: 2rem;
    }
    .footer-cover {
        width: 100%;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
    }
    .footer-logo-container {
        margin-bottom: 0;
    }
    .footer-nav {
        margin: 20px 0;
    }
    .footer-cover-title,
    .text-page-body .footer p,
    .footer p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1rem;
        margin: 20px auto 15px;
    }
}
@media (max-width: 767px) {
    .footer-cover {
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: center;
    }
    .footer-inner-container {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 2rem;
    }
    .footer-logo {
        display: block;
        width: 15rem;
        margin: 0 auto 1.5rem;
    }
    .footer {
        padding: 10px 0;
    }
    .home-page-cover .footer-cover-title {
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0;
    }
    .footer-nav li span {
        display: none;
    }
    .footer-nav li a {
        line-height: 2.1rem;
        margin-left: 0;
    }
    .footer-nav {
        flex-direction: column;
        align-items: flex-start;
        margin: 20px auto;
    }
}
