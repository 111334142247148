.page-not-found-container,
.page-not-found-block {
  position: relative;
}
.page-not-found-block {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
}
.page-not-found-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 7rem;
  object-fit: cover;
  object-position: center;
}
.page-not-found-main-img {
  position: relative;
  height: calc(100vh - 10rem);
  width: 100%;
  padding-top: 9rem;
  display: flex;
  justify-content: center;
}
.page-not-found-main-img img {
  height: 100%;
  width: auto;
}

/* RESPONSIVE */
@media (max-width: 1350px) {
  .page-not-found-main-img img {
    height: 100%;
    width: auto;
  }
}
@media (max-width: 1025px) {
  .page-not-found-img {
    position: relative;
    height: calc(100vh - 400px);
  }
  .page-not-found-main-img {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .page-not-found-main-img {
    height: auto;
    width: 100%;
  }
  .page-not-found-main-img img {
    height: inherit;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .page-not-found-img {
    height: calc(100vh - 52vh);
  }
}
