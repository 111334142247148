.lp-top-games-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  z-index: 1;
}
.lp-top-games-block-background {
  position: absolute;
  top: -13%;
  left: 0;
  width: 100%;
  height: 130%;
  z-index: 0;
}
.lp-top-games-title-block {
  position: relative;
  width: fit-content;
  text-align: center;
}
.lp-top-games-title-block .title {
  font-size: 3.25rem;
  color: var(--btn-hover);
}
.lp-top-games-title-left,
.lp-top-games-title-right {
  position: absolute;
  top: -3.5rem;
  width: 10rem;
}
.lp-top-games-title-left {
  left: -10rem;
}
.lp-top-games-title-right {
  right: -10rem;
  transform: scaleX(-1);
}
.lp-top-game-icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.lp-top-game-icons-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lp-top-game-icons-block .game-image-block {
  width: 20%;
}
.lp-top-game-icons-block.middle-block .game-image-block {
  width: 30%;
}
.lp-top-game-icons-block .game-image-block img {
  width: 100%;
  height: auto;
  margin: 1.5rem 0;
}

/* RESPONSIVE */
@media (max-width: 1025px) {
  .lp-top-game-icons-block {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .lp-top-game-icons-block.middle-block .game-image-block,
  .lp-top-game-icons-block .game-image-block {
    width: 40%;
  }
  .lp-top-game-icons-block .game-image-block img {
    margin: 0.5rem 0;
  }
  .lp-top-games-title-block .title {
    width: 50%;
    margin: 0 auto;
  }
  .lp-top-games-title-left, 
  .lp-top-games-title-right {
    top: -1rem;
  }
  .lp-top-games-title-left {
    left: 2.5rem;
  }
  .lp-top-games-title-right {
    right: 2.5rem;
  }
}
@media (max-width: 767px) {
  .lp-top-games-block {
    margin-top: 7rem;
  }
  .lp-top-games-title-block .title {
    width: 90%;
    margin: 0 auto 1rem;
    font-size: 2.2rem;
    line-height: 2.5rem;
  }
  .lp-top-game-icons-container {
    margin-top: 0;
    padding: 0;
  }
  .lp-top-games-title-left {
    width: 6rem;
    top: -3rem;
    left: -2rem;
    transform: rotate(45deg);
  }
  .lp-top-games-title-right {
    width: 6rem;
    top: -3rem;
    right: -2rem;
    transform: rotate(125deg);
  }
  .lp-top-game-icons-block.middle-block .game-image-block {
    width: 100%;
  }
  .lp-top-game-icons-block .game-image-block {
    width: 50%;
  }
  .lp-top-game-icons-block .game-image-block button {
    padding: .5rem 1rem;
    font-size: 2.3rem;
  }
  .lp-top-game-icons-block.middle-block .game-image-block button {
    padding: .5rem 1.5rem;
    font-size: 2.5rem;
  }
}
