.lp-steps-banner {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 6;
}
.lp-display-banner {
  /* min-height: 100vh; */
  height: 100%;
  width: 130%;
  display: inline;
  z-index: -1;
}
.lp-mobile-banner {
  cursor: pointer;
  display: none;
}
.lp-btn {
  width: fit-content;
  margin: 2rem 0 1rem;
  background: var(--btn-hover);
  text-shadow: 1px 4px 6px var(--btn-hover), 0 0 0 #000, 1px 4px 6px var(--btn-hover);
}
.steps-mobile {
  position: absolute;
  height: 100%;
  width: 100%;
}
.lp-tablet-banner,
.lp-mobile-banner {
  display: none;
}
.steps-main-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.steps-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  width: 100%;
}
.step-text-container {
  position: relative;
}
.step-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step-text {
  position: absolute;
  top: 5%;
  left: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.step-2-text {
  top: 9%;
  left: 22%;
  width: 65%;
}
.step-3-text {
  top: 10%;
  left: 32%;
  width: 60%;
}
.step-text-container h3 {
  font-family: "LuckiestGuy", sans-serif;
  font-size: 2rem;
  line-height: 2.3rem;
  text-transform: uppercase;
  -webkit-text-stroke: 1px var(--black);
  color: var(--btn-hover);
}
.step-text p {
  font-size: 1.1rem;
}
.step-bg {
  width: 60%;
  margin-top: -5rem;
}
.step-2-bg {
  width: 20.5rem;
  margin: -10rem 0 0 5rem;
}
.step-3-bg {
  margin-left: 4rem;
  width: 16.5rem;
}


/* RESPONSIVE */
@media (max-width: 1350px) {
  .lp-display-banner {
    width: 150%;
  }
  .step-block {
    width: 35%;
  }
  .step-2-bg {
    width: 85%;
  }
  .step-3-bg {
    width: 15.5rem;
  }
  .step-text-bg {
    width: 100%;
  }
  .step-text p {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .step-2-text {
    top: 11%;
  }
}
@media (max-width: 1100px) {
  .lp-display-banner {
    width: 170%;
  }
}
@media (max-width: 1025px) {
  .steps-inner-container {
    flex-direction: column;
    justify-content: center;
    margin-top: 12rem;
    margin-bottom: 0;
  }
  .lp-display-banner {
    display: none;
  }
  .steps-main-container {
    position: relative;
  }
  .lp-steps-banner {
    margin-top: 0;
    background-image: url('/public/assets/images/lp/lp-steps-bg-tablet.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .step-block {
    width: 100%;
    max-width: 800px;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: center;
  }
  .step-bg {
    width: 20%;
    margin: 0;
    transform: scaleX(-1);
  }
  .step-2-bg {
    transform: scaleX(1);
  }
  .step-text-container {
    width: 50%;
  }
  .step-text {
    position: absolute;
    top: 35%;
    left: 16%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
  }
}
@media (max-width: 767px) {
  .steps-inner-container {
    margin-top: 10rem;
  }
  .step-block {
    width: 95%;
  }
  .step-text-container {
    width: 75%;
  }
  .step-bg {
    width: 25%;
  }
  .step-text {
    top: 38%;
    left: 10%;
    width: 80%;
  }
  .step-text p {
    font-size: .9rem;
    line-height: 1.2rem;
  }
  /* .banner-title {
    width: 100%;
  }
  .step-bg {
    display: none;
  }
  .step-text-container {
    height: 100%;
    width: 80%;
  }
  .steps-inner-container {
    margin-top: 10rem;
    margin-bottom: -10rem;
  }
  .step-block,
  .step-block:nth-child(2),
  .step-block:last-child {
    justify-content: center;
  }
  .lp-btn {
    z-index: 10;
  } */
}
@media (orientation: landscape) and (max-height: 400px) {
  /* .lp-steps-banner img {
    max-height: none;
    height: 150vh;
  } */
}
