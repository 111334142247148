.about-body {
  background-image: url('/public/assets/images/about-bg.webp');
  background-size: inherit;
  background-position: left calc(100% - 281px);
  background-repeat: no-repeat;
}
.about-container {
  display: flex;
  margin: 8rem auto 0;
  padding-top: 5rem;
  min-height: calc(100vh - 34.8rem);
}
.about-text-container {
  width: 50%;
  padding: 0 0 8rem 10%;
}
.about-title {
  width: 70%;
  margin-bottom: 2rem;
}
.about-img-container {
  position: relative;
  width: 50%;
}
.about-image {
  position: absolute;
  top: -3rem;
  left: 0;
  width: 140%;
  height: auto;
}

/* RESPONSIVE */
@media (max-width: 1350px) {
  .about-body {
    background-image: none;
  }
  .about-text-container {
    padding: 0 0 8rem 3%;
  }
  .about-image {
    top: 2rem;
    width: 120%;
  }
}
@media (max-width: 1025px) {
  .about-body {
    background-image: url('/public/assets/images/about-bg-tablet.webp');
    background-size: contain;
    background-position: 30% 20%;
  }
  .about-container {
    flex-direction: column;
    margin: 0 auto 2rem;
  }
  .about-title {
    width: 70%;
  }
  .about-text-container {
    width: 80%;
    padding: 0;
  }
  .about-img-container {
    width: 100%;
    padding: 0;
  }
  .about-image {
    position: relative;
    top: 0;
    left: 0;
    width: 90%;
    height: auto;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .about-body {
    background-image: none;
  }
  .about-container {
    margin: 0 auto 2rem;
    padding-top: 3rem;
  }
  .about-title {
    width: 100%;
  }
  .about-text-container,
  .about-img-container {
    width: 100%;
  }
  .about-image {
    width: 110%;
    margin-left: -5%;
  }
}
