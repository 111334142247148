.optout-form {
    width: 23rem;
    padding-top: 1%;
}
.users__contact-body {

}
.users__contact-form-cover {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 0 100px;
}
.opt-out-cover .users__contact-form-cover .container {
    margin: 1rem auto 0;
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    padding: 0 15px;
}
.user-item {
    border-bottom: 1px dotted #000;
    padding: 10px 0;
}
.user-item li {
    margin: 3px 0;
}