.top5-page-body {
  position: relative;
}
.top5-main-container {
  width: 90%;
  margin: 7rem auto 2rem;
  padding-top: 4rem;
  text-align: center;
}
.top5-games-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.top5-games-cover {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
}
.top5-games-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}
.top5-games-container .top5-games-image-block {
  width: 30%;
}
.top5-games-middle-container .top5-games-image-block {
  width: 25%;
}
.top5-games-container img{
  width: 100%;
  height: auto;
}

.text-page-top5 {
  min-height: 55vh;
  background: linear-gradient(180deg, #0F336F 0%, #23529F 100%);
  background-size: cover;
  padding: 17vh 0 300px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
}
.content-cover-top5 {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background: #0E367A;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  text-align: center;
  display: block;
}

.content-cover-top5 h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  margin: 0;
  display: block;
  padding: 37px 0;
  color: #F6F4F4;
}
.top5-games-wrap{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.game-item{
  position: relative;
  max-width: 100%;
  display: flex;
  background: #FFCC4B;
  border: 2px solid #000000;
  margin: 55px auto;
  min-height: 233px;
}
/*.game-item .label{*/
/*  position: absolute;*/
/*  background: #FFD600;*/
/*  border: 3px solid #0D5EFC;*/
/*  border-radius: 20px;*/
/*  font-family: 'Open Sans', sans-serif;*/
/*  font-weight: 800;*/
/*  font-size: 20px;*/
/*  text-align: center;*/
/*  text-transform: uppercase;*/
/*  color: #0D5EFC;*/
/*  display: block;*/
/*  max-width: 248px;*/
/*  padding: 11px 0;*/
/*  top: -30px;*/
/*  left: -45px;*/
/*  width: 100%;*/
/*}*/
.game-item__left-block{
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.game-item__left-block .image-block{
  width: 100%;
  max-width: 288px;
}
.game-item__left-block .game-img{
  max-width: 215px;
  margin: 0 auto;
}
.game-item__left-block .rankings{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: rgba(132, 77, 65, 0.75);
  box-shadow: 0px 2px 5px rgb(0 0 0 / 25%);
  height: 100%;
}
.votes-block,
.rating-block{
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  /*padding: 0 40px;*/
}
.votes-block p,
.rating-block p{
  font-family: "PaytoneOne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}
.rating-block p.score-number{
  /*display: block;*/
  font-size: 36px;
  text-align: center;
  color: #FFD24C;
}
.game-item__right-block{
  position: relative;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: inherit;
  padding: 20px;
  color: #000000;
  font-family: "PaytoneOne", sans-serif;
}
.game-item_right-block__text{
  text-align: left;
  width: 49%;
}
.game-item__right-block .highlights{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
}
.game-item__right-block .highlights-description{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.top5-play-btn{
  width: 49%;
  max-width: 238px;
  padding: 0.85rem 1rem;
  font-family: "LuckiestGuy", sans-serif;
  font-size: 2.2rem;
  -webkit-text-stroke: 2px var(--black);
  color: rgba(255,203,47, 0.8);
  text-shadow: 1px 4px 6px var(--btn), 0 0 0 #000, 1px 4px 6px var(--btn);
  text-transform: uppercase;
  background: var(--btn);
  border: 2px solid var(--black);
  transition: all .5s;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.top5-play-btn:hover {
  background-color: #F53A30
;
}

@media (max-width: 1100px){
  .game-item {
    margin: 45px auto;
    min-height: 180px;
  }
  .game-item__left-block .game-img {
    max-width: 60%;
  }
  .stars-container .star-img{
    max-width: 18px;
  }
  .votes-block p, .rating-block p {
    font-size: 17px;
    line-height: 1;
  }
  .rating-block p.score-number {
    font-size: 30px;
  }
  .game-item__right-block .highlights {
    font-size: 20px;
  }
  .game-item__right-block .highlights-description {
    font-size: 14px;
    line-height: 20px;
  }
  .top5-play-btn {
    max-width: 163px;
    padding: 5px 0;
    font-size: 24px;
  }
}
@media (max-width: 770px){
  .game-item{
    padding: 20px 20px 10px;
  }
  .game-item__left-block{
    width: 40%;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .game-item__left-block .rankings{
    width: 95%;
    height: 50px;
    padding: 10px 0;
  }
  .game-item__right-block{
    width: 60%;
    background: transparent;
    padding: 0px 0px 0 25px;
    flex-direction: column;
  }
  .game-item__left-block .game-img {
    max-width: 200px;
  }
  .rating-block{
    flex-direction: column-reverse;
  }
  .votes-block, .rating-block {
    height: unset;
  }
  .game-item_right-block__text{
    margin: 0 auto;
  }
  .top5-play-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 700px){
  .content-cover-top5 h2 {
    font-size: 22px;
  }
}
@media (max-width: 450px){
  .top5-games-title{
    font-size: 2rem;
    line-height: 2.3rem;
    width: 80%;
    margin: auto;
  }
  .text-page-top5 {
    padding: 17vh 0 477px;
  }
  .game-item {
    margin: 15px auto;
    padding: 10px;
    min-height: 135px;
    justify-content: space-between;
  }
  .game-item__left-block {
    justify-content: space-between;
    width: 47%;
  }
  .game-item__left-block .rankings{
    max-height: 21px;
    padding: 7px 0 10px;
    width: 95%;
  }
  .game-item__right-block{
    padding: 0px 0px 0 14px;
    display: block;
    width: 47%;
  }
  .game-item__left-block .game-img {
    max-width: 113px;
  }
  .stars-container .star-img {
    max-width: 13px;
  }
  .votes-block,
  .rating-block {
    justify-content: left;
    /*height: 100%;*/
  }
  .votes-block p,
  .rating-block p {
    font-size: 12px;
    justify-content: left;
  }
  .rating-block p.score-number {
    font-size: 16px;
  }
  .game-item_right-block__text{
    width: 95%;
    margin: 0;
  }
  .game-item__right-block .highlights {
    font-size: 14px;
  }
  .game-item__right-block .highlights-description {
    font-size: 11px;
    line-height: 13px;
  }
  .top5-play-btn {
    width: 100%;
    max-width: 137px;
    padding: 7px 0;
    font-size: 16px;
    bottom: 0;
    left: auto;
    transform: none;
  }
}

/* RESPONSIVE */
@media (max-width: 767px) {
  .top5-games-container {
    flex-direction: column;
    margin-bottom: 0;
  }
  .top5-games-container .top5-games-image-block {
    width: 90%;
    margin: 1rem 0;
  }
}
@media (max-width: 420px){
  .content-cover-top5 h2 {
    font-size: 17px;
    padding: 18px 0;
  }
}