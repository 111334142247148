.lp-top-game-block {
  position: relative;
  margin-top: 5rem;
}
.lp-top-game-img-container {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110%;
}
.lp-top-game-banner {
  position: relative;
  width: 60%;
  height: auto;
  object-fit: cover;
}
.lp-top-game-img-empty {
  width: 40%;
}
.lp-top-game-text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.lp-top-game-text-inner-container {
  display: flex;
  height: 100%;
  z-index: 10;
}
.lp-top-game-title {
  padding-top: 2rem;
  color: var(--btn-hover);
}
.lp-top-game-text-block {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}
.lp-top-game-text-banner {
  width: 100%;
  height: auto;
  margin: 2rem 0 1rem;
}
.lp-top-game-text {
  margin-bottom: 2rem;
}
.lp-top-game-text-empty {
  width: 50%;
}

/* RESPONSIVE */
@media (max-width: 1500px) {
  .lp-top-game-img-container {
    position: absolute;
  }
  .lp-top-game-text-container {
    position: relative;
  }
  .lp-top-game-banner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 55%;
  }
  .lp-top-game-img-empty {
    width: 45%;
  }
}
@media (max-width: 1350px) {

}
@media (max-width: 1025px) {
  .lp-top-game-text-container {
    flex-direction: column-reverse;
  }
  .lp-top-game-block {
    display: flex;
    flex-direction: column-reverse;
  }
  .lp-top-game-text-block {
    width: 100%;
    align-items: flex-start;
    padding-left: 0;
  }
  .lp-top-game-text-banner {
    width: 90%;
  }
  .lp-top-game-text {
    width: 95%;
    z-index: 2;
  }
  .lp-top-game-banner {
    position: relative;
    width: 90%;
  }
  .lp-top-game-img-container {
    position: relative;
    display: flex;
    width: 100%;
  }
  .lp-top-game-img-empty,
  .lp-top-game-text-empty {
    display: none;
  }
}
@media (max-width: 767px) {
  .lp-top-game-text-container {
    flex-direction: column;
  }
  .lp-top-game-block {
    flex-direction: column;
  }
  .lp-top-game-banner {
    width: 97%;
    left: 0;
  }
  .lp-top-game-text-banner {
    width: 100%;
  }
  .lp-top-game-text {
    width: 100%;
    margin-bottom: 2rem;
  }
}
