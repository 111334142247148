.opt-out {
  position: relative;
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin: 210px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.opt-out h3 {
  font-size: 25px;
  margin: 25px 0 15px;
}

.contact-mobile-container {
  display: flex;
  align-items: normal;
  flex-direction: row-reverse;
}

.opt-out__contact-body {
  /*background-image: url("/assets/images/contact-background.webp");*/
  background-image: none;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: top right;
  margin-top: 0;
}
.contact-image-container {
  position: relative;
  width: auto;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.optout-form-cover {
  /*position: absolute;*/
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
}
.opt-out-cover .optout-form-cover .main-container {
  margin-top: 1rem;
  /*height: calc(100vh - 18rem);*/
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.optout-form {
  width: 23rem;
  /*padding-top: 20%;*/
}
.optout-form-cover input, .optout-form-cover textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-family: "PaytoneOne";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  border: 2px solid var(--main-font);
  color: var(--main-font);
  background-color: #FFCC4B;
  box-sizing: border-box;
}
.optout-form-cover form .main-btn {
  margin-top: 1rem;
  display: block;
  text-align: center;
}

.empty-form-block {
  width: 55%;
}
.empty-image-block {
  width: 35%;
}
.contact-image-cover {
  width: 65%;
  height: 100%;
  min-height: calc(100vh - 18rem);
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.contact-image-cover img {
  width: 50vh;
}
.text-page-body.contact-page-bg {
  background-position: center right;
}
form label {
  width: 100%;
  position: relative;
}
.optout-form-cover form .error_message {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 15px;
  bottom: -33px;
  color: var(--error);
  font-weight: 700;
  font-size: 14px;
}
.show-success-message {
  line-height: normal;
  margin-top: 20px;
}

.optout-form .main-btn {
  padding: 0.5rem 2.5rem;
  font-family: "LuckiestGuy", sans-serif;
  font-size: 3.625rem;
  line-height: 4rem;
  -webkit-text-stroke: 2px var(--black);
  color: rgba(255, 203, 47, 0.8);
  text-shadow: 1px 4px 6px var(--btn), 0 0 0 #000, 1px 4px 6px var(--btn);
  text-transform: uppercase;
  background: var(--btn);
  border: 2px solid var(--black);
  transition: all 0.3s;
  cursor: pointer;
}

/* RESPONSIVE */
@media (max-width: 1025px) {
  .opt-out__contact-body {
    background-image: none;
  }
  .opt-out__contact-body .contact-mobile-container {
    display: flex;
    flex-direction: column-reverse;
    background-image: none;
    /*background-image: url("/assets/images/contact-background-mobile.webp");*/
    /*background-size: 100%;*/
    /*background-position: bottom center;*/
    /*background-repeat: no-repeat;*/
  }
  .optout-form-cover .main-container {
    margin-top: 10rem;
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .optout-form-cover {
    position: relative;
    width: 100%;
  }
  .contact-form {
    width: 50vw;
    padding-top: 0;
  }
  .contact-image-container {
    margin-top: -7rem;
  }
  .contact-image-cover {
    width: 100%;
    height: 100%;
    min-height: auto;
    margin-top: 10rem;
  }
  .contact-image-cover img {
    width: 40vh;
  }
  /*.opt-out-cover .optout-form-cover .main-container {*/
  /*  margin-top: 0;*/
  /*}*/
}
@media (max-width: 767px) {
  .contact-mobile-container {
    background-image: none;
  }
  .contact-form {
    width: 100%;
    text-align: center;
  }

}
